<script>
import moment from "moment";
import CInput from "@/components/c-input/index.vue";
import {
    addDriverWithdrawRuleApi,
    getDriverWithdrawRuleApi,
    updateDriverWithdrawRuleApi
} from "@/www/urls/driverWithdrawal";

const initForm = () => ({
    withdrawWeekDay: [],  //  提现周期
    time: [moment().startOf('d').toDate(), moment().endOf('d').toDate()],
    startTime: "0800",  // 开始时段
    endTime: "1200",  //结束时段
    withdrawCount: 1, //提现次数
    withdrawDayScope: 1, //  结算范围
    lslPrice: 0.1, //  下限
    uslPrice: undefined,  //上限
    totalPrice: undefined,//
})
export default {
    name: "DriverWithdrawalSetting",
    data() {
        return {
            loading: false,
            weeks: '一、二、三、四、五、六、日'.split('、').map((label, index) => ({
                value: index + 1,
                label: '周' + label
            })),
            nums: new Array(99).fill('').map((item, index) => ({
                value: index + 1,
                label: index + 1
            })),
            form: initForm(),
            rules: {
                'withdrawWeekDay.weekIndex': [{
                    required: true,
                    message: '请选择提现周期'
                }],
                lslPrice: [{
                    validator: (rule, value, callback) => {
                        if (!value) {
                            callback(new Error('请输入'));
                        } else if (value < 0.1) {
                            callback(new Error('不能小于0.1'));
                        } else {
                            callback();
                        }
                    }
                }],
                totalPrice: [{
                    validator: (rule, value, callback) => {
                        if (!value) {
                            callback()
                            // callback(new Error('请输入'));
                        } else if (value < 0.1) {
                            callback(new Error('不能小于0.1'));
                        } else {
                            callback();
                        }
                    }
                }],
                uslPrice: [{
                    validator: (rule, value, callback) => {
                        if (!value) {
                            callback()
                            // callback(new Error('请输入'));
                        } else if (value < 0.1) {
                            callback(new Error('不能小于0.1'));
                        } else {
                            callback();
                        }
                    }
                }]
            }
        }
    },
    components: {CInput},
    mounted() {
        this.getData()
    },
    methods: {
        async getData() {
            try {
                this.loading = true
                const ret = await getDriverWithdrawRuleApi()
                if (!ret.success && ret.errors[0].message) {
                    this.$message.error(ret.errors[0].message)
                }
                if (ret.data && ret.data.id) {
                    this.form = {
                        id: ret.data.id,
                        withdrawWeekDay: JSON.parse(ret.data.withdrawWeekDay),
                        withdrawCount: ret.data.withdrawCount,
                        withdrawDayScope: ret.data.withdrawDayScope,
                        lslPrice: ret.data.lslPrice,
                        uslPrice: ret.data.uslPrice,
                        totalPrice: ret.data.totalPrice,
                        time: [
                            moment('2025-03-25 ' + ret.data.startTime, 'YYYY-MM-DD HHmm').toDate(),
                            moment('2025-03-25 ' + ret.data.endTime, 'YYYY-MM-DD HHmm').toDate(),
                        ]
                    }
                }
            } finally {
                this.loading = false
            }
        },
        async handleSubmit() {
            this.loading = true
            try {
                await this.$refs.form.validate()
                const form = {
                    ...this.form,
                    withdrawWeekDay: JSON.stringify(this.form.withdrawWeekDay.sort((a, b) => a - b > 0 ? 1 : -1)),
                    startTime: moment(this.form.time[0]).format('HHmm'),
                    endTime: moment(this.form.time[1]).format('HHmm'),
                    time: undefined
                }
                let ret;
                if (this.form.id) {
                    ret = await updateDriverWithdrawRuleApi(form)
                } else ret = await addDriverWithdrawRuleApi(form)
                console.log(form);
                if (ret.success && ret.data) {
                    this.$message.success("保存成功！")
                    this.getData()
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<template>
    <div class="driver-withdrawal-setting" v-loading="loading">
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont">
                <el-form label-width="120px" class="form" ref="form" :model="form" :rules="rules">
                    <el-form-item label="提现周期：">
                        <div style="display:flex;">
                            <span class="mr10">每月第</span>
                            <el-form-item prop="withdrawWeekDay">
                                <ApiSelect
                                    v-model="form.withdrawWeekDay"
                                    multiple
                                    :options="new Array(31).fill('').map((i,j)=>({label:j+1,value:j+1}))"></ApiSelect>
                            </el-form-item>
                            <span class="ml10 mr10">日</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="提现时段：" prop="time">
                        <el-time-picker
                            is-range
                            format="HH:mm"
                            start="00:00"
                            end="24:00"
                            :clearable="false"
                            v-model="form.time"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            placeholder="选择时间范围">
                        </el-time-picker>
                    </el-form-item>
                    <el-form-item label="结算范围：" prop="withdrawDayScope">
                        <span class="mr10">提现日前</span>
                        <ApiSelect :options="nums" filterable v-model="form.withdrawDayScope"></ApiSelect>
                        <span class="ml10">天的订单，即司机可提现的订单范围</span>
                    </el-form-item>
                    <el-form-item label="提现次数：" prop="withdrawCount">
                        <span class="mr10">提现日当天最多可提现</span>
                        <ApiSelect :options="nums" filterable v-model="form.withdrawCount"></ApiSelect>
                        <span class="ml10">次，如提现失败次数不计</span>
                    </el-form-item>
                    <el-form-item label="提现额度：">
                        <div style="display: flex">
                            <span class="mr10">额度上限：每司机单日最多可提现</span>
                            <el-form-item prop="totalPrice">
                                <c-input placeholder="请输入" v-model="form.totalPrice" clearable>
                                    <span slot="suffix">元</span>
                                </c-input>
                            </el-form-item>
                            <span class="mr10 ml10">，单笔最多</span>
                            <el-form-item prop="uslPrice">
                                <c-input placeholder="请输入" v-model="form.uslPrice" clearable>
                                    <span slot="suffix">元</span>
                                </c-input>
                            </el-form-item>
                        </div>
                    </el-form-item>
                    <el-form-item label=" ">
                        <div style="display: flex">
                            <span class="mr10">额度下限：</span>
                            <!--                            <el-form-item>-->
                            <!--                                <c-input placeholder="请输入" :value="0.1" disabled>-->
                            <!--                                    <span slot="suffix">元</span>-->
                            <!--                                </c-input>-->
                            <!--                            </el-form-item>-->
                            <span class=" mr10">单笔最少</span>
                            <el-form-item prop="lslPrice">
                                <c-input placeholder="请输入" v-model="form.lslPrice">
                                    <span slot="suffix">元</span>
                                </c-input>
                            </el-form-item>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </el-main>
        <el-footer v-if="!loading">
            <div class="footer-cont">
                <el-button style="width: 200px" type="primary" @click="handleSubmit">保存</el-button>
            </div>
        </el-footer>
    </div>
</template>

<style scoped lang="scss">
.driver-withdrawal-setting {
    .form {
        padding: 20px;
        background-color: #fff;
        height: auto;
        margin-top: 10px;
    }

    .footer-cont {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        border-top: 1px solid #f8f8f8;
    }
}
</style>
